export const experienceData = [
  {
    date: "April, 2023 - Present",
    company: "Koinot Software Interprise",
    url: "https://bazark.vercel.app/en",
    description: "",
    role: "Frontend Developer",
    works: [
      "As a Front End Web Developer, I contributed to the development of an e-commerce platform named Bazark, creating user profiles, payment pages, footers, navbars, and more using Material UI, Redux Toolkit, and Axios.",
    ],
  },
  {
    date: "May, 2023 - July, 2023",
    company: "Itransition Group",
    url: "https://www.itransition.com",
    description: "",
    role: "Software development Internship",
    works: [
      "During my internship, I worked on various tasks using React, Redux Toolkit, Axios, Material UI, and Ant Design for the frontend. ",
      "For backend development, I utilized Node.js, Express.js, and MongoDB. ",
      "I hosted the frontend on Netlify and Vercel, and the backend on Cyclic and Render.",
      "My final project was the Collections Manager application, which can be accessed this link: https://collections-manager.netlify.app",
    ],
  },

  {
    date: "March, 2023 - April, 2023",
    company: "Jobo.uz",
    url: "https://www.jobo.uz",
    description: "",
    role: "Frontend Developer",
    works: [
      "At Jobo.uz, I developed search filters for the jobo.uz site and created a website for testing new employees. ",
      "Additionally, I built endomarine-kids.uz, which includes a feature for users to submit their name and phone number, with the data being sent to Telegram.",
      "I specialize in creating dynamic and engaging single-page websites."
    ],
  },
  {
    date: "November, 2022 - March 2023",
    company: "IT Group",
    url: "",
    description: "",
    role: "Frontend Developer",
    works: [
      " Developed the ShareMe application, a social media platform for uploading pictures, writing comments, and signing in with Google accounts. Built the frontend with React and used Sanity for the backend.Enhanced user interaction and community engagement. Link: https://www.shareme.uz/",
      "Created a React-based website called Cryptoverse using Chart.js and RTK Query. Provided information on cryptocurrencies, news, and exchanges. Developed a user-friendly interface to enhance visitor experience. Link: https://gayratjondev.github.io/cryptoverse/",
     "Developed an e-commerce website using HTML, CSS, JSX, React, Redux, and Redux-Thunk. Implemented features to improve user navigation and shopping experience. Link: https://gosto.netlify.app/"
    ],
  },
  {
    date: "January, 2022 - November, 2022",
    company: "TenX",
    url: "https://gayratjondev.github.io/TenX/login",
    description: "",
    role: "Frontend Developer",
    works: [
      "I worked on the TenX project, which involved creating a React website with HTML, CSS, Sass, JavaScript, React, Redux, and Strapi for the backend. ",
      "This site includes video lessons for learning JavaScript, available this link: https://gayratjondev.github.io/TenX/login",
    ],
  },
];

export const testimonals = [
  {
    id: 1,
    name: "Shukurullo Abdubannayev",
    title: "Android Developer",
    src: "https://twitter.com/",
    img: "https://i.ibb.co/sK3pwmC/shukurullo.jpg",
    icon: "assets/twitter.png",
    desc: "Always willing to help, incredibly competent, on time, hardworking and reliable. In my experience, no project is too difficult, and with Gayratjon you have a truly unique entity - someone who can simplify a sometimes very complex industry. I can recommend his services without hesitation.",
  },
  {
    id: 2,
    name: "Baxtiyor Chorshanbiyev",
    title: "Software Engineer",
    src: "https://youtube.com/",
    img: "https://i.ibb.co/T418zxf/baxtiyor.jpg",
    icon: "assets/youtube.png",
    desc: "I have seen your work and it is brilliant, may Allah give development in your work and high reputation. Although, I have not taught you a lit things, you always appreciate my work. I have had a deep respect not only for your knowledge, but also for your humanity too.",
    featured: true,
  },
  {
    id: 3,
    name: "Beknazar Oltiboyev",
    title: "Frontend Developer",
    src: "https://www.linkedin.com/",
    img: "https://i.ibb.co/7C4khrt/beknazar.jpg",
    icon: "assets/linkedin.png",
    desc: "Working with Gayratjon was better than expected and we had really high expectations. He's an incredibly talented developer, but what really sets him apart is his work ethic and steady approach. From time to time, and without us asking, he added improvements and improvements that resulted in a better final product for us and our customers.",
  },
];